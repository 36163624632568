import $ from "jquery";
import DataTable from "datatables.net";
//import 'datatables.net-plugins/api/sum().mjs';
import { getDataTableDom } from "../utils/appUtils";
import TableDT from "../UI/TableDT";

let ROUTE_URL = "";

let dtTable = {
  getTableOptions: () => {
    const dtOptions = {
      ordering: true, lengthChange: true, filter: true, paging: true, info: true, processing: true,
      order: [[1, "asc"], [3, "asc"]],
      ajax: { type: "GET", url: ROUTE_URL },
      dom: getDataTableDom("lfip", 3, 1),
      columns: [
        { data: "id", title: "Код абонента", className: "dt-body-right", },
        { data: "name", title: "Абонент", titleFooter: "Итого", render: DataTable.render.text(), },
        { data: "contract_id", title: "Код договора", className: "dt-body-right", },
        { data: "number", title: "№ договора", render: DataTable.render.text(), },
        { data: "dat", title: "Дата договора", "render": DataTable.render.moment("DD.MM.YYYY", true), },
        { data: "sum_accruals", title: "Списано", className: 'dt-body-right dt-head-center dt-nowrap sum', render: DataTable.render.number(' ', '.', 2), },
        { data: "sum_payments", title: "Оплачено", className: 'dt-body-right dt-head-center dt-nowrap sum', render: DataTable.render.number(' ', '.', 2), },
        { data: "sum_debt", title: "Остаток", className: 'dt-body-right dt-head-center dt-nowrap sum', render: DataTable.render.number(' ', '.', 2), },
      ],
      footerCallback: function (row, data, start, end, display) {
        let api = this.api();
        api.columns('.sum').every(function (index) {
          let v_sum = api.column(index, { search: 'applied' }).data().sum();
          $(this.footer()).html(DataTable.render.number(' ', '.', 2).display(v_sum)).addClass("text-end");
        });
      },
    };
    return dtOptions;
  }
};

function ReportContract({ typ }) {
  ROUTE_URL = "/calculations/" + typ;

  return (
    <div className="container-xxl">
      <TableDT getOptions={() => dtTable.getTableOptions()} addFooter />
    </div>
  );
}

export default ReportContract;
