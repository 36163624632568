import React from "react";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import DataTable from 'datatables.net'
import { MainArea, MainAreaHeader, MainAreaBody } from "../layout/MainArea";
import { getDataTableDom } from '../utils/appUtils'
import TableDT from "../UI/TableDT";

const ROUTE_URL = "/rules";

const Rules = () => {
  let navigate = useNavigate();

  const handleAfterDTCreate = ({ DOM, Api }) => {
    dtTable.Api = Api;
    $(DOM).on('click', 'tbody button[name="btnShowRoles"]', function (e) {
      e.preventDefault();
      let data = dtTable.Api.row($(this).parents('tr')).data();
      let urlStr = "/rules/" + data.id + "/roles";
      navigate(urlStr);
    });
  }

  return (
    <MainArea>
      <MainAreaHeader caption="Доступы" />
      <MainAreaBody containerClass="container-xxl">
        <TableDT getOptions={() => dtTable.getTableOptions(0)} afterCreate={handleAfterDTCreate} />
      </MainAreaBody>
    </MainArea >
  );
}

let dtTable = {
  Api: null,
  getTableOptions: (crud) => {
    const dtOptions =
    {
      ordering: true, lengthChange: true, filter: true, paging: false, info: true, processing: true,
      order: [[1, "asc"]],
      dom: getDataTableDom("iflp", 1, 1),
      ajax: { type: "GET", url: ROUTE_URL },
      columns: [
        { data: "id", title: "Код", className: "dt-body-right dt-head-center" },
        { data: "name", title: "Наименование", render: DataTable.render.text() },
        { data: "description", title: "Описание", render: DataTable.render.text() },
        { data: "roles_count", title: "Кол-во ролей", className: "dt-center td-btn", render: DataTable.render.btnDetail("btnShowRoles"), },
      ],
    };
    return dtOptions;
  }
}

export default Rules;
