// import { Routes, Route } from "react-router-dom";
import { createBrowserRouter, createRoutesFromElements, Route, } from "react-router-dom";

import "./components/utils/DataTablesUtils";
import Layout from "./components/layout/Layout.jsx";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword";
import ErrorPage from "./components/ErrorPage.js";
//import Game from "./components/game/Game";
import Home from "./components/home/Home";
import Login from "./components/auth/Login";
import ChangePassword from "./components/auth/ChangePassword";
import Rules from "./components/access/Rules.jsx";
import Roles from "./components/access/Roles.jsx";
import SettingAccess from "./components/access/SettingAccess.jsx";
import SysUsers from "./components/access/SysUsers.jsx";
import SysUser from "./components/access/SysUser.jsx";
import Subscribers from "./components/others/Subscribers.jsx";
import Contracts from "./components/contract/Contracts.jsx";
import Contract from "./components/contract/Contract.jsx";
import Services from "./components/setting/Services.jsx";
import Units from "./components/setting/Units.jsx";
import TariffPlans from "./components/setting/TariffPlans.jsx";
import TariffServices from "./components/setting/TariffServices.jsx";
import Calculations from "./components/others/Calculations.jsx";
import Reports from "./components/others/Reports";
import Reference from "./components/others/Reference";
import DeviceTypes from "./components/setting/DeviceTypes";

const App = createBrowserRouter(
   createRoutesFromElements(
      <>
         <Route path="/login" element={<Login />} />
         <Route path="/forgot_password" element={<ForgotPassword />} />
         <Route path="/reset_password/:token/:login" element={<ResetPassword />} />
         <Route path="/" element={<Layout />} errorElement={<ErrorPage />}>
            <Route index element={<Home />} />
            <Route path="changepassword" element={<ChangePassword />} errorElement={<ErrorPage />} />
            <Route path="rules" element={<Rules />} errorElement={<ErrorPage />} />
            <Route path="rules/:id/roles" element={<SettingAccess />} errorElement={<ErrorPage />} />
            <Route path="roles" element={<Roles />} errorElement={<ErrorPage />} />
            <Route path="roles/:id/rules" element={<SettingAccess />} errorElement={<ErrorPage />} />
            <Route path="roles/:id/sys_users" element={<SettingAccess />} errorElement={<ErrorPage />} />
            <Route path="sys_users" element={<SysUsers />} errorElement={<ErrorPage />} />
            <Route path="sys_users/:id" element={<SysUser />} errorElement={<ErrorPage />} />
            <Route path="subscribers" element={<Subscribers />} errorElement={<ErrorPage />} />
            <Route path="subscribers/:id/contracts" element={<Contracts />} errorElement={<ErrorPage />} />
            <Route path="subscribers/:id/contracts/:id_contract" element={<Contract />} errorElement={<ErrorPage />} />
            <Route path="services" element={<Services />} errorElement={<ErrorPage />} />
            <Route path="device_types" element={<DeviceTypes />} errorElement={<ErrorPage />} />
            <Route path="units" element={<Units />} errorElement={<ErrorPage />} />
            <Route path="tariff_plans" element={<TariffPlans />} errorElement={<ErrorPage />} />
            <Route path="tariff_plans/:id/tariff_services" element={<TariffServices />} errorElement={<ErrorPage />} />
            <Route path="calculations" element={<Calculations />} errorElement={<ErrorPage />} />
            <Route path="reports" element={<Reports />} errorElement={<ErrorPage />} />
            <Route path="reference" element={<Reference />} errorElement={<ErrorPage />} />
            {/* <Route path="game" element={<Game />} errorElement={<ErrorPage />} /> */}
            <Route path="*" element={<ErrorPage errorCode="404" />} />
         </Route>
      </>
   )
);

export default App;
