import { ModalWindow } from "../UI/ModalWindow";
import { Input } from '../UI/FormElements'

const ROUTE_URL = "/tariff_plans";
let initialData = {
  data: { name: "", description: "", dat_begin: "", dat_end: "" },
  format: { dat_begin: "date", dat_end: "date" }
};

function TariffPlan({ params, handleClose }) {

  let urlSubmit, urlGet;
  if (params.isNew) {
    urlSubmit = ROUTE_URL;
    urlGet = ""; //или ROUTE_URL + "/create" если при создании НАДО предварительное обращение к серверу
  }
  else {
    urlGet = ROUTE_URL + "/" + params.id;
    urlSubmit = urlGet;
  }
  return (
    <ModalWindow params={params} handleClose={handleClose} initialData={initialData} urlGet={urlGet} urlSubmit={urlSubmit} >
      <TariffPlanFields />
    </ModalWindow>
  );
}

const TariffPlanFields = ({ dopParams, ...elementProps }) => {
  return (
    <div className="row form-gutter">
      <div className="col-12">
        <Input label="Наименование тарифного плана" name="name" {...elementProps} required autoFocus />
      </div>
      <div className="col-12">
        <Input label="Описание" name="description" {...elementProps} required />
      </div>
      <div className="col-md-6">
        <Input label="Действует с" type="date" name="dat_begin"  {...elementProps} required />
      </div>
      <div className="col-md-6">
        <Input label="Действует по" type="date" name="dat_end"  {...elementProps} />
      </div>
    </div>
  )
}

export default TariffPlan;