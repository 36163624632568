import { MainArea, MainAreaHeader, MainAreaBody } from "../layout/MainArea"
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import DataTable from 'datatables.net'
import Accruals from "../contract/Accruals";
import Payments from "../contract/Payments";
import Corrections from "../contract/Corrections";

function Calculations() {

  const handleTabSelect = (eventKey) => {
    DataTable.tables({ visible: true, api: true }).columns.adjust();
  }

  return (
    <MainArea>
      <MainAreaHeader caption="Расчеты" />
      <MainAreaBody>
        <Tab.Container defaultActiveKey="groupAccruals">
          <Nav as="ul" className="mb-2 our-nav no-gutters nav nav-fill">
            <Nav.Item as="li"><Nav.Link eventKey="groupAccruals">Счета на оплату</Nav.Link></Nav.Item>
            <Nav.Item as="li"><Nav.Link eventKey="groupPayments">Поступления средств</Nav.Link></Nav.Item>
            <Nav.Item as="li"><Nav.Link eventKey="groupCorrections">Списания</Nav.Link></Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="groupAccruals" onEntering={handleTabSelect}>
              <Accruals crud="15" contract_id="-1" />
            </Tab.Pane>
            <Tab.Pane eventKey="groupPayments" onEntering={handleTabSelect}>
              <Payments crud="15" contract_id="-1" />
            </Tab.Pane>
            <Tab.Pane eventKey="groupCorrections" onEntering={handleTabSelect}>
              <Corrections crud="15" contract_id="-1" />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </MainAreaBody>
    </MainArea>
  );
}

export default Calculations;
