import { ModalWindow } from "../UI/ModalWindow";
import { Input, Select } from '../UI/FormElements'

let initialData = {
  data: {
    typ_str: "", model: "", state_number: "", loc_number: "", description: "", device_type_id: 1,
    serial_number: "", inventory_number: "", imei: "", tc: "", disabled_inputs: "", dat_begin: "", dat_end: "", contract_id: null
  },
  format: { dat_begin: "date", dat_end: "date" }
};

function Device({ params, contract_id, ROUTE_URL, handleClose }) {
  let urlSubmit, urlGet;
  if (params.isNew) {
    urlSubmit = ROUTE_URL;
    urlGet = ROUTE_URL + "/create"; //или ROUTE_URL + "/create" если при создании НАДО предварительное обращение к серверу
  }
  else {
    urlGet = ROUTE_URL + "/" + params.id;
    urlSubmit = urlGet;
  }

  initialData.data.contract_id = contract_id;
  return (
    <ModalWindow params={params} handleClose={handleClose} initialData={initialData} urlGet={urlGet} urlSubmit={urlSubmit} >
      <DeviceFields />
    </ModalWindow >
  );
}

const DeviceFields = ({ dopParams, ...elementProps }) => {
  return (
    <div className="row form-gutter">
      <div className="col-md-6">
        <Input label="Тип" name="typ_str" {...elementProps} required autoFocus />
      </div>
      <div className="col-md-6">
        <Input label="Модель" name="model" {...elementProps} required />
      </div>
      <div className="col-md-6">
        <Input label="Гос. номер" name="state_number"{...elementProps} />
      </div>
      <div className="col-md-6">
        <Input label="Гаражный номер" name="loc_number" {...elementProps} />
      </div>
      <div className="col-md-4">
        <Select label="Тип устройства" name="device_type_id" {...elementProps} selectOptions={dopParams.serverData.device_types} />
      </div>
      <div className="col-md-4">
        <Input label="Серийный номер" name="serial_number" {...elementProps} />
      </div>
      <div className="col-md-4">
        <Input label="Инв. номер" name="inventory_number"{...elementProps} />
      </div>
      <div className="col-md-6">
        <Input label="IMEI" name="imei" {...elementProps} />
      </div>
      <div className="col-md-6">
        <Input label="ТС" name="tc" {...elementProps} />
      </div>
    </div>
  )
}
export default Device;
