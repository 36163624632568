import Select from 'react-select/async'
import '../../css/react-select.css'
import { requestToServer } from '../utils/appUtils'

let loadUrl;
const loadOptions = (inputValue, callback) => {
  if (inputValue.length <= 3)
    callback([]);
  else
    requestToServer(loadUrl + `?search_mask=${inputValue}`, { method: 'GET' }, (data) => { callback(data) }, (error) => { console.log(error) }, false);
};

export const SelectReactAsync = (props) => {
  let { label, data, errors, ...params } = props;
  let error = (typeof errors === "object" && !Array.isArray(errors)) ? errors[params.name] : errors;

  params = {
    classNamePrefix: "react-select", noOptionsMessage: () => "Нет данных",
    placeholder: "Введите данные для поиска ...", ...params
  };
  params.className = "react-select-container " + (params.className ? params.className : "") + (error ? " is-invalid" : "");
  if (typeof params.loadOptions === "string") {
    loadUrl = params.loadOptions;
    params.loadOptions = loadOptions;
  }
  let element;
  element = (<Select {...params} />);
  return (
    <>
      {label && <label className="form-label">{label}</label>}
      {element}
      <div className="invalid-feedback">{error}</div>
    </>
  );
}
