import React from 'react';
import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';

let themeColor = {
  "light": "Светлая", "dark": "Темная", "semi-dark": "Темное меню"
};
let menuLayout = {
  "vertical-menu": "Вертикальное", "compact-menu": "Вертикальное компактное", "horizontal-menu": "Горизонтальное",
};
let primaryColor = ['#0d6efd', "#3e8ef7", "#1e3d73", "#0bb2d4", "#17b3a3", "#eb6709", "#76c335", '#ffc107', '#dc3545'];

const Settings = ({ settings, onChange }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let themeColorJSX = [], menuLayoutJSX = [];
  for (let key in themeColor) {
    themeColorJSX.push((
      <div key={key} >
        <label className={`chkbox ${themeColorJSX.length !== 0 ? "mt-2" : ""}`}>{themeColor[key]}
          <input type="radio" name="themeColor" className="mt-2" value={key} onChange={onChange} checked={(settings.themeColor ?? "light") === key} />
          <span className="checkmark"></span>
        </label>
      </div>
    ));
  }

  for (let key in menuLayout) {
    menuLayoutJSX.push((
      <div key={key}>
        <label className={`chkbox ${menuLayoutJSX.length !== 0 ? "mt-2" : ""}`}>{menuLayout[key]}
          <input type="radio" name="menuLayout" className="mt-2" value={key} onChange={onChange} checked={(settings.menuLayout ?? "vertical-menu") === key} />
          <span className="checkmark"></span>
        </label>
      </div>
    ));
  }

  return (
    <>
      <Offcanvas id="settings" show={show} onHide={handleClose} placement="end" backdrop="static">
        <Offcanvas.Header className="border-bottom" closeButton>
          <Offcanvas.Title>Настройки</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <h6>Основной цвет</h6>
          <ul className="list-inline float-start claerfix mb-0" style={{ caretColor: "transparent !important" }}>
            {
              primaryColor.map((item, ind) => {
                return (<li key={ind} name="colorBox" className="color-box m-2 list-inline-item float-start" style={{ backgroundColor: item }} role="button"
                  data-color={item} onClick={onChange}></li>)
              })
            }
            <li className="list-inline-item">
              <input type="color" name="primaryColor" role="button" className="color m-2" value={settings.primaryColor ?? primaryColor[0]} onChange={onChange} />
            </li>
          </ul>

          <hr className="w-100" />

          <h6>Цветовая тема</h6>
          {themeColorJSX}

          <hr className="w-100" />

          <h6>Меню</h6>
          {menuLayoutJSX}
        </Offcanvas.Body>
      </Offcanvas>
      <a href="#settings" className="setting" tabIndex="-1" role="button" onClick={handleShow} >
        <h5 className="mb-0"><i className="icon-settings"></i></h5>
      </a>
    </>
  );
}

export default React.memo(Settings);
