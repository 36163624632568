import { useRef, useEffect, useState } from "react";
import { Chart, Legend, Tooltip, PieController, ArcElement } from 'chart.js';
import CardHeaderDashboard from "./cardHeaderDashboard";
import { requestToServer } from "../utils/appUtils";

Chart.register(Tooltip, PieController, ArcElement, Legend);

const data = {
  labels: [],
  datasets: [{
    label: ' количество',
    data: [],
    borderWidth: 1,
    backgroundColor: ['#CB4335', '#1F618D', '#F1C40F', '#27AE60', '#ff0000', '#0000ff','#F4A460','#7B68EE','#FA8072'],
  }]
};

const config = {
  type: 'pie', data: data,
  options: {
    responsive: true, maintainAspectRatio: true,
    plugins: { legend: { position: 'top', }, }
  },
};

let chartJS;

const CardServices = (props) => {
  const [loading, setLoading] = useState(false);
  const graphRef = useRef(null);

  const handleClick = (event) => {
    if (loading) return;
    event.preventDefault();
    event.target.blur();
    refreshData();
  }

  const refreshData = () => {
    setLoading(true);
    requestToServer(props.url, { method: 'GET' }, (response) => {
      chartJS.data.datasets[0].data = [];
      chartJS.data.labels = [];
      response.data.forEach(element => {
        chartJS.data.datasets[0].data.push(element.kol);
        chartJS.data.labels.push(element.name);
      });
      chartJS.update();
      setLoading(false);
    }, (error) => { setLoading(false); console.error('dashboard', error) }, false);
  }

  useEffect(() => {
    chartJS = new Chart(graphRef.current.getContext("2d"), config);
    refreshData();

    return () => { chartJS.destroy(); }
  }, []);

  return (
    <div className="card h-100">
      <CardHeaderDashboard loading={loading} onClick={handleClick}>
        Мониторинг услуг
      </CardHeaderDashboard>
      <div className="card-body d-flex justify-content-center pb-4" style={{ height: "500px" }}>
        <canvas ref={graphRef} style={{ maxHeight: "100%" }}/>
      </div>
    </div>
  );
}

export default CardServices;