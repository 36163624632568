import { useState, useEffect } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import $ from "jquery";
import DataTable from "datatables.net";
import { MainArea, MainAreaHeader, MainAreaBody } from "../layout/MainArea";
import { getDataTableDom, requestToServer } from "../utils/appUtils";
import TableDT from "../UI/TableDT";

let ROUTE_URL = "";

let dtTable = {
  Api: null,
  getTableOptions: (crud) => {
    const dtOptions = {
      ordering: true, lengthChange: true, filter: true, paging: true, info: true, processing: true,
      order: [[1, "asc"]],
      dom: getDataTableDom("lfip", 2, 1),
      ajax: { type: "GET", url: ROUTE_URL },
      columns: [
        { orderable: false, searchable: false, data: null, className: "dt-center td-btn-action", render: DataTable.render.btnDelete(), },
        { data: "id", title: "Код", className: "dt-body-right", },
        { data: "name", title: "Наименование", render: DataTable.render.dataToAnchor("btnShowEdit"), },
        { data: "number", title: "№", render: DataTable.render.text(), },
        { data: "dat", title: "Дата заключения", render: DataTable.render.moment("DD.MM.YYYY", false), },
        { data: "dat_start", title: "Дата начала", render: DataTable.render.moment("DD.MM.YYYY", false), },
        { data: "dat_end", title: "Дата окончания", render: DataTable.render.moment("DD.MM.YYYY", false), },
        { data: "created_at", title: "Дата создания", render: DataTable.render.moment("DD.MM.YYYY HH:mm:ss", true), },
        { data: "updated_at", title: "Дата изменения", render: DataTable.render.moment("DD.MM.YYYY HH:mm:ss", true), },
      ],
    };
    if ((crud & 1) === 0) {
      dtOptions.order = [[0, "asc"]];
      dtOptions.columns.splice(0, 1);
      dtOptions.dom = getDataTableDom("lfip", 1, 1);
    }
    return dtOptions;
  }
};

function Contracts(props) {
  const [serverData, setServerData] = useState(null);
  const { showErrorPage, messageBox } = useOutletContext();
  const navigate = useNavigate();

  let currentLocation = useLocation();
  ROUTE_URL = currentLocation.pathname;

  const handleAddRow = () => {
    navigate("./create");
  };
  const handleAfterDTCreate = ({ DOM, Api }) => {
    dtTable.Api = Api;
    $(DOM).on('click', 'tbody button[name="btnDelete"]', function (e) {
      e.preventDefault();
      let data = dtTable.Api.row($(this).parents('tr')).data();
      let urlDelete = "/contracts/" + data.id;
      messageBox({
        show: true, message: `Вы действительно хотите удалить "${data.name}"?`, mbOk: (e) => {
          e.preventDefault();
          messageBox({ show: false, mbOk: null });
          requestToServer(urlDelete, { method: 'DELETE' }, () => { dtTable.Api.ajax.reload(); }, (error) => { alert(JSON.stringify(error)); }, false);
        }
      });
    });
    $(DOM).on('click', 'tbody a[name="btnShowEdit"]', function (e) {
      e.preventDefault();
      let data = dtTable.Api.row($(this).parents('tr')).data();
      let urlStr = "./" + data.id;
      navigate(urlStr);
    });
  }

  useEffect(() => {
    requestToServer(ROUTE_URL + "?crud", { method: 'GET' }, setServerData, showErrorPage, true);
  }, []);

  if (serverData === null)
    return <MainArea loading />;

  return (
    <>
      <MainArea>
        <MainAreaHeader caption={`Договоры ${serverData.parent.name}`} />
        <MainAreaBody>
          <div>
            {
              (serverData.crud & 8) !== 0 && <button type="button" className="btn btn-primary btn-sm btn-our me-2" onClick={handleAddRow}>Добавить</button>
            }
            <button type="button" className="btn btn-secondary btn-sm btn-our" onClick={() => { navigate(-1); }}>Закрыть</button>
          </div>
          <TableDT getOptions={() => dtTable.getTableOptions(serverData.crud)} afterCreate={handleAfterDTCreate} />
        </MainAreaBody>
      </MainArea>
    </>
  );
}

export default Contracts;