import CardSystems from "./cardSystems";
import CardAbonents from './cardAbonents';
import CardLogins from './cardLogins';
import CardServices from './cardServices';

const ROUTE_URL = "/dashboard/";
const Home = (props) => {

  return (
    <div className="container-fluid px-3 py-1 dashboard">
      <div className="row g-3">
        <div className="col-md-12 col-lg-12 col-xl-6">
          <CardAbonents url={ROUTE_URL + "subscribers"} />
        </div>
        <div className="col-md-12 col-lg-12 col-xl-6">
          <CardSystems url={ROUTE_URL + "systems"} />
        </div>
        <div className="col-md-12 col-lg-12 col-xl-6">
          <CardServices url={ROUTE_URL + "services"} />
        </div>
        <div className="col-md-12 col-lg-12 col-xl-6">
          <CardLogins url={ROUTE_URL + "lastLogins"} />
        </div>
      </div>
    </div>
  );
}

export default Home;

