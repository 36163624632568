import { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import moment from "moment/moment";
import $ from "jquery";
import DataTable from 'datatables.net'
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { MainArea, MainAreaHeader, MainAreaBody } from "../layout/MainArea";
import { requestToServer, formatServerErorrs } from "../utils/appUtils";
import { Input, TextArea, ButtonSpinner } from '../UI/FormElements'
import Accruals from "./Accruals";
import Devices from "./Devices";
import Payments from "./Payments";
import ContractTariffPlans from "./ContractTariffPlans";
import TableDT from "../UI/TableDT";

const ROUTE_URL = "/contracts";
let initialData = {
  data: { name: "", description: "", number: "", dat: "", dat_start: "", dat_end: "", subscriber_id: null },
  format: { dat: "date", dat_start: "date", dat_end: "date", }
};

let dtServices = {
  DOM: null,
  getTableOptions: (crud, data) => {
    const dtOptions =
    {
      ordering: true, lengthChange: false, filter: false, paging: false, info: false, processing: false,
      order: [[1, "asc"]],
      data: data,
      columns: [
        {
          data: "contracts_count", title: "Подключена", className: "dt-center", width: "15%",
          render: function (data, type, row, meta) {
            return type === 'display' ? `<input type="checkbox" name="belongs[]" class="form-check-input" value="${row.id}" ${data == 1 ? "checked" : ""} />` : data;
          }
        },
        { data: "name", title: "Услуга", render: DataTable.render.text() },
      ],
    };
    return dtOptions;
  }
};

const Contract = (props) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState(initialData.data);
  const [serverData, setServerData] = useState(null);
  const { showErrorPage } = useOutletContext();
  const navigate = useNavigate();

  let urlParams = useParams();
  let params = { isNew: urlParams.id_contract == "create", id: urlParams.id_contract }

  const afterLoadData = (response) => {
    if (params.isNew) {
      setData({ ...initialData.data, subscriber_id: urlParams.id });
      setServerData(response);
    }
    else {
      let newData = {};
      for (let key in initialData.data) {
        if (initialData.format[key] === "date")
          newData[key] = (key in response.data && response.data[key] ? response.data[key].substring(0, 10) : initialData.data[key]);
        else
          newData[key] = (key in response.data ? response.data[key] : initialData.data[key]);
      }
      setData(newData);
      setServerData(response);
    }
  }

  const handleClose = (event) => {
    if (loading) return;
    navigate(-1);
  }
  const handleChange = (event) => {
    if (loading) return;
    setData({ ...data, [event.target.name]: event.target.value });
  }
  const handleTabSelect = (eventKey) => {
    DataTable.tables({ visible: true, api: true }).columns.adjust();
  }

  const handleSubmitForm = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setErrors({});
    setLoading(true);
    let formData = new FormData();
    for (let key in data)
      formData.append(key, data[key]);
    $(dtServices.DOM).find('tbody input[name="belongs[]"]:checked').each((index, element) => {
      formData.append("belongs[]", $(element).val());
    });
    requestToServer(ROUTE_URL + (params.isNew ? "" : "/" + params.id), { method: params.isNew ? 'POST' : 'PATCH', body: formData },
      (response) => { navigate(-1); }, (error) => { setLoading(false); setErrors(formatServerErorrs(event.target, error)); }, false);
  }
  const handleAfterDTCreate = ({ DOM, Api }) => {
    dtServices.DOM = DOM;
  }

  useEffect(() => {
    requestToServer(ROUTE_URL + (params.isNew ? "/create" : "/" + params.id), { method: 'GET' }, afterLoadData, showErrorPage, true);
  }, []);

  if (serverData === null)
    return <MainArea loading />;

  let caption = "Просмотр договора", isCanSave = false;
  if (params.isNew) {
    caption = "Добавление договора";
    isCanSave = true;
  }
  else if ((serverData.crud & 2) !== 0) {
    caption = "Изменение";
    isCanSave = true;
  }

  let elementProps = { data, errors, onChange: handleChange };
  let contractInfo = (
    <div className="container-xxl">
      <form onSubmit={handleSubmitForm} autoComplete="off">
        <fieldset disabled={loading}>
          <div className="row form-gutter">
            <div className="col-12">
              <Input label="Наименование договора" name="name" {...elementProps} required autoFocus />
            </div>

            <div className="col-md-6">
              <Input label="Номер договора" name="number" {...elementProps} required />
            </div>
            <div className="col-md-6">
              <Input label="Дата заключения" type="date" name="dat" {...elementProps} required />
            </div>

            <div className="col-md-6">
              <Input label="Действует с" type="date" name="dat_start" {...elementProps} required />
            </div>
            <div className="col-md-6">
              <Input label="Действует по" type="date" name="dat_end" {...elementProps} />
            </div>
            <div className="col-12">
              <TextArea label="Описание договора" name="description" rows="3" {...elementProps} required />
            </div>
          </div>
          <div className="py-2">
            <h6 className="card-title text-light-new text-center">Услуги</h6>
            <TableDT getOptions={() => dtServices.getTableOptions(serverData.crud, serverData.services)} afterCreate={handleAfterDTCreate} />
          </div>
          {errors._message_ && <div className="form-error mb-2">{errors._message_}</div>}
          <div>
            {isCanSave && <ButtonSpinner className="btn-sm me-2" loading={loading}>Сохранить</ButtonSpinner>}
            <button type="button" className="btn btn-secondary btn-sm btn-our" onClick={handleClose}>Закрыть</button>
          </div>
        </fieldset>
      </form>
    </div>
  );

  if (params.isNew) {
    return (
      <MainArea>
        <MainAreaHeader caption={caption} />
        <MainAreaBody>
          {contractInfo}
        </MainAreaBody>
      </MainArea>
    );
  }
  else {
    let cardHeader = (
      <MainAreaHeader className="card-header py-1 px-2 border">
        <div className="d-flex" >
          <div className="flex-grow-1">
            <h6 className="pt-2">{serverData.subscriber.name}: договор №{data.number} с&nbsp;
              {moment(data.dat_start).isValid() ? moment.utc(data.dat_start).local().format("DD.MM.YYYY") : data.dat_start} по
              {moment(data.dat_end).isValid() ? moment.utc(data.dat_end).local().format("DD.MM.YYYY") : data.dat_end}
            </h6>
          </div>
          <div>
            <button className="btn btn-close ms-2" onClick={handleClose}></button>
          </div>
        </div>
      </MainAreaHeader>
    );

    return (
      <MainArea>
        {cardHeader}
        <MainAreaBody>
          <Tab.Container defaultActiveKey="contractInfo">
            <Nav as="ul" className="mb-2 our-nav no-gutters nav nav-fill">
              <Nav.Item as="li">
                <Nav.Link eventKey="contractInfo">Реквизиты</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link eventKey="devicesInfo">Оборудование</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link eventKey="accrualsInfo">Списано</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link eventKey="paymentInfo">Оплачено</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link eventKey="tariffPlanInfo">Тарифы</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="contractInfo">
                {contractInfo}
              </Tab.Pane>
              <Tab.Pane eventKey="devicesInfo" onEntering={handleTabSelect}>
                <Devices crud={serverData.crud} contract_id={params.id} />
              </Tab.Pane>
              <Tab.Pane eventKey="accrualsInfo" onEntering={handleTabSelect}>
                <Accruals crud={serverData.crudAccruals} contract_id={params.id} />
              </Tab.Pane>
              <Tab.Pane eventKey="paymentInfo" onEntering={handleTabSelect}>
                <Payments crud={serverData.crudPayments} contract_id={params.id} />
              </Tab.Pane>
              <Tab.Pane eventKey="tariffPlanInfo" onEntering={handleTabSelect}>
                <ContractTariffPlans crud={serverData.crud} contract_id={params.id} />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </MainAreaBody>
      </MainArea>
    );
  }
}

export default Contract;
