import { ModalWindow } from "../UI/ModalWindow";
import { Input } from '../UI/FormElements'

const ROUTE_URL = "/subscribers";

const initialData = {
  name: "", inn: "", jur_address: "", checking_account: "", bank: "", correspondent_account: "", bic: "", manager: "", phone: "", email: ""
};



function Subscriber({ params, handleClose }) {
  let urlSubmit, urlGet;
  if (params.isNew) {
    urlSubmit = ROUTE_URL;
    urlGet = ""; //или ROUTE_URL + "/create" если при создании НАДО предварительное обращение к серверу
  }
  else {
    urlGet = ROUTE_URL + "/" + params.id;
    urlSubmit = urlGet;
  }
  return (
    <ModalWindow params={params} handleClose={handleClose} initialData={initialData} urlGet={urlGet} urlSubmit={urlSubmit} >
      <SubscriberFields />
    </ModalWindow>
  );
}

const SubscriberFields = ({ dopParams, ...elementProps }) => {
  return (
    <div className="row form-gutter">
      <div className="col-12">
        <Input label="Наименование" name="name" {...elementProps} required autoFocus />
      </div>
      <div className="col-12">
        <Input label="Юридический адрес" name="jur_address" {...elementProps} required />
      </div>
      <div className="col-12">
        <Input label="Руководитель" name="manager" {...elementProps} required />
      </div>

      <div className="col-md-4">
        <Input label="БИК" name="bic" {...elementProps} required />
      </div>
      <div className="col-md-8">
        <Input label="Банк" type="bank" name="bank" {...elementProps} required />
      </div>

      <div className="col-md-4">
        <Input label="ИНН/КПП" name="inn" {...elementProps} required />
      </div>
      <div className="col-md-4">
        <Input label="Расчетный счет" name="checking_account" {...elementProps} required />
      </div>
      <div className="col-md-4">
        <Input label="Корреспондентский счет" name="correspondent_account" {...elementProps} required />
      </div>

      <div className="col-md-6">
        <Input label="Телефон" name="phone" {...elementProps} required />
      </div>
      <div className="col-md-6">
        <Input label="Email" name="email" {...elementProps} required />
      </div>
    </div>
  )
}

export default Subscriber;
