import { Input } from "../UI/FormElements"

export const AuthInput = ({ label, ...params }) => {
  return (
    <div className="mb-3 row gx-2">
      <label htmlFor="login" className="col-sm-3 col-md-3 col-form-label text-sm-end">{label}</label>
      <div className="col-sm-7 col-md-7">
        <Input {...params} />
      </div>
    </div>
  )
}

export const AuthPanelButton = (props) => {
  return (
    <div className="row">
      <div className="col-sm-8 col-md-8 offset-sm-3 offset-md-3">
        {props.children}
      </div>
    </div>
  )
}
