import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { MainArea, MainAreaHeader, MainAreaBody } from "../layout/MainArea";
import DataTable from 'datatables.net'
import ReportSubscriber from "./ReportSubscriber";
import ReportContract from "./ReportContract";
import ReportCalculations from "./ReportCalculations";
import ReportServices from "./ReportServices";

const handleTabSelect = (eventKey) => {
  DataTable.tables({ visible: true, api: true }).columns.adjust();
}

function Reports() {
  return (
    <MainArea>
      <MainAreaHeader caption="Отчеты" />
      <MainAreaBody>
        <Tab.Container defaultActiveKey="groupSubscriber">
          <Nav as="ul" className="mb-2 our-nav no-gutters nav nav-fill">
            <Nav.Item as="li"><Nav.Link eventKey="groupSubscriber">по Абонентам</Nav.Link></Nav.Item>
            <Nav.Item as="li"><Nav.Link eventKey="groupContract">по Договорам</Nav.Link></Nav.Item>
            {/* <Nav.Item as="li"><Nav.Link eventKey="groupServices">по Услугам</Nav.Link></Nav.Item>
            <Nav.Item as="li"><Nav.Link eventKey="groupCalculations">по Взаиморасчетам</Nav.Link></Nav.Item> */}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="groupSubscriber">
              <ReportSubscriber typ="subscriber" />
            </Tab.Pane>
            <Tab.Pane eventKey="groupContract" onEntering={handleTabSelect}>
              <ReportContract typ="contract" />
            </Tab.Pane>
            {/* <Tab.Pane eventKey="groupServices" onEntering={handleTabSelect}>
              <ReportServices />
            </Tab.Pane>
            <Tab.Pane eventKey="groupCalculations" onEntering={handleTabSelect}>
              <ReportCalculations />
            </Tab.Pane> */}
          </Tab.Content>
        </Tab.Container>
      </MainAreaBody>
    </MainArea>
  );
}

export default Reports;
