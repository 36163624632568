import { useState, useEffect } from "react";
import { requestToServer } from "../utils/appUtils";
import CardHeaderDashboard from "./cardHeaderDashboard";

const CardAbonents = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const refreshData = () => {
    setLoading(true);
    requestToServer(props.url, { method: 'GET' }, (response) => {
      setData(response.data);
      setLoading(false);
    }, (error) => { setLoading(false); console.error('dashboard', error) }, false);
  }

  const handleClick = (event) => {
    if (loading) return;
    event.preventDefault();
    event.target.blur();
    refreshData();
  }

  useEffect(() => {
    refreshData();
  }, []);

  return (
    <div className="card h-100">
      <CardHeaderDashboard loading={loading} onClick={handleClick}>
        Мониторинг абонентов
      </CardHeaderDashboard>
      <div className="card-body">
        {(
          <div className="container-fluid px-1">
            <div className="row gy-3 gx-3">
              <div className="col-md-6">
                <h5 className="card-title my-0">Кол-во абонентов</h5>
                <h2 className="text-info ms-3">
                  <span>{data.count_abonents !== undefined ? new Intl.NumberFormat('ru-RU', { style: 'decimal' }).format(data.count_abonents) : "-"}</span>
                </h2>
              </div>
              <div className="col-md-6">
                <h5 className="card-title my-0">Кол-во НАП </h5>
                <h2 className="text-info ms-3">
                  <span>{data.count_devices !== undefined ? new Intl.NumberFormat('ru-RU', { style: 'decimal' }).format(data.count_devices) : "-"}</span>
                </h2>
              </div>
              <div className="col-md-6">
                <h5 className="card-title my-0">Начислено</h5>
                <h2 className="text-info ms-3">
                  <span>{data.sum_invoice !== undefined ? new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(data.sum_invoice) : "-"}</span>
                </h2>
              </div>
              <div className="col-md-6">
                <h5 className="card-title my-0">Оплачено</h5>
                <h2 className="text-info ms-3">
                  <span>{data.sum_pay !== undefined ? new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(data.sum_pay) : "-"}</span>
                </h2>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CardAbonents;
