import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { enterChangeFocus, requestToServer, formatServerErorrs } from '../utils/appUtils'
import AuthWindow from "./AuthWindow";
import { ButtonSpinner } from "../UI/FormElements";
import { AuthInput, AuthPanelButton } from "./AuthElements";

const ResetPassword = () => {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  let urlParams = useParams();

  const handleSubmitForm = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let formData = new FormData(event.target);
    formData.append("token", urlParams.token);
    formData.append("login", urlParams.login);
    setLoading(true);
    setErrors({});
    requestToServer("/reset-password", { method: 'POST', body: formData },
      (response) => { return navigate("/login"); },
      (error) => { setLoading(false); setErrors(formatServerErorrs(event.target, error)); }, false);
  }

  return (
    <AuthWindow errors={errors}>
      <form onSubmit={handleSubmitForm} autoComplete="off">
        <fieldset disabled={loading}>
          <AuthInput label="Логин" name="login" defaultValue={urlParams.login} errors={errors} disabled={true} />
          <AuthInput label="Новый пароль" type="password" name="password" onKeyDown={enterChangeFocus} errors={errors} required autoFocus />
          <AuthInput label="Повторите пароль" type="password" name="password_confirmation" onKeyDown={enterChangeFocus} errors={errors} required />
          <AuthPanelButton>
            <ButtonSpinner loading={loading}>Сохранить пароль</ButtonSpinner>
          </AuthPanelButton>
        </fieldset>
      </form>
    </AuthWindow>
  );
}

export default ResetPassword;