import moment from "moment/moment";
import { useState } from "react";
import CardHeaderDashboard from "./cardHeaderDashboard";

let services = [
  { id: 1, name: "ЛСН", status: 0, date: (new Date((new Date()) - 25000 * 750 * 60)) },
  { id: 2, name: "РОС", status: 0, date: (new Date((new Date()) - 23000 * 10150 * 60)).toISOString() },
  { id: 3, name: "ГНСС", status: 1, date: (new Date((new Date()) - 33000 * 110150 * 60)) },
  { id: 4, name: "А-ГНСС", status: 0, date: (new Date((new Date()) - 43000 * 20150 * 60)) },
  { id: 5, name: "РРР", status: 1, date: (new Date((new Date()) - 11000 * 15150 * 60)) },
];


const CardSystems = () => {
  const [loading, setLoading] = useState(false);

  const handleClick = (event) => {
    if (loading) return;
    event.preventDefault();
    event.target.blur();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }


  return (
    <div className="card h-100">
      <CardHeaderDashboard loading={loading} onClick={handleClick}>
        Мониторинг служб
      </CardHeaderDashboard>
      <div className="card-body" style={{ fontSize: "0.85rem" }}>
        {(
          services.map((item, index, array) => {
            return (
              <div key={item.id} className={`col-12 d-flex align-items-center ${index == 0 ? "" : "mt-1"}`}>
                <i className={`fs-5 me-2 ${item.status == 0 ? "bi-check-circle-fill text_green" : "bi-x-circle-fill text-danger"}`}></i>
                <div>
                  <span className="link-info">
                    {item.name} - <small>{moment(item.date).isValid() ? moment.utc(item.date).local().format("DD.MM.YYYY HH:mm:ss") : item.date}</small>
                  </span>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div >
  );
}

export default CardSystems;