import { useState, } from "react";
import { useNavigate, Link } from "react-router-dom";
import { enterChangeFocus, requestToServer, formatServerErorrs } from '../utils/appUtils'
import AuthWindow from "./AuthWindow";
import { ButtonSpinner } from "../UI/FormElements";
import { AuthInput, AuthPanelButton } from "./AuthElements";

const Login = () => {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmitForm = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let formData = new FormData(event.target);
    setLoading(true);
    setErrors({});
    requestToServer("/login", { method: 'POST', body: formData },
      (response) => {
        localStorage.setItem('token', 'Bearer ' + response.token);
        return navigate("/");
      }, (error) => { setLoading(false); setErrors(formatServerErorrs(event.target, error)); }, false);
  }

  return (
    <AuthWindow errors={errors}>
      <form onSubmit={handleSubmitForm}>
        <fieldset disabled={loading}>
          <AuthInput label="Логин" name="login" onKeyDown={enterChangeFocus} errors={errors} required autoFocus />
          <AuthInput label="Пароль" type="password" name="password" onKeyDown={enterChangeFocus} errors={errors} required />
          <AuthPanelButton>
            <ButtonSpinner loading={loading}>Войти</ButtonSpinner>
            <Link to="/forgot_password" className="ms-2">Забыли пароль?</Link>
          </AuthPanelButton>
        </fieldset>
      </form>
    </AuthWindow>
  );
}

export default Login;