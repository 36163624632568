import { useState, useReducer } from "react";
import { useOutletContext } from "react-router-dom";
import $ from "jquery";
import DataTable from "datatables.net";
import moment from "moment/moment";
import { getDataTableDom, requestToServer } from '../utils/appUtils'
import { reducerModalWindow } from "../UI/ModalWindow";
import SelectPeriod from "../UI/SelectPeriod";
import Correction from "./Correction";
import TableDT from "../UI/TableDT";

const ROUTE_URL = "/corrections";
let dtTable = {
  Api: null,
  dtParams: { dat_start: moment().startOf("month").format("YYYY-MM-DD"), dat_end: moment().format("YYYY-MM-DD") },
  getTableOptions: (crud, url, showAll, setLoading) => {
    const dtOptions = {
      ordering: true, lengthChange: true, filter: true, paging: true, info: true, processing: true,
      order: [[2, "desc"], [1, "desc"]],
      ajax: {
        type: "GET", url: url, "data": function () { return dtTable.dtParams; },
        "beforeSend": function () { setLoading(true); },
        "complete": function () { setLoading(false); },
      },
      dom: getDataTableDom("lfip", 2, 1),
      columns: [
        { orderable: false, searchable: false, data: null, className: "dt-center th-btn-action", render: DataTable.render.btnDelete(), },
        { data: "id", title: "Код", className: "dt-body-right", },
        { data: "dat", title: "Дата списания", titleFooter: "Итого", render: DataTable.render.moment("DD.MM.YYYY", false, "btnShowEdit"), },
        { data: 'summa', title: "Сумма", className: 'dt-body-right dt-head-center dt-nowrap sum', render: DataTable.render.number(' ', '.', 2), },
        { data: "comment", title: "Описание", render: DataTable.render.text(), },
        { data: "name", title: "Абонент", render: DataTable.render.text(), },
        { data: "dogovor", title: "Договор", render: DataTable.render.text(), },
        { data: "user_name", title: "Ответственный", render: DataTable.render.text(), },
        { data: "created_at", title: "Дата ввода", render: DataTable.render.moment("DD.MM.YYYY HH:mm:ss", true), },
      ],
      "footerCallback": function (row, data, start, end, display) {
        let api = this.api();
        api.columns('.sum').every(function (index) {
          let v_sum = Math.abs(api.column(index, { search: 'applied' }).data().sum());
          $(this.footer()).html(DataTable.render.number(' ', '.', 2).display(v_sum)).addClass("text-end");
        });
      },
    };
    if (showAll != -1)
      dtOptions.columns.splice(5, 2);

    if ((crud & 1) === 0) {
      dtOptions.order = [[0, "asc"]];
      dtOptions.columns.splice(0, 1);
      dtOptions.dom = getDataTableDom("lfip", 1, 1);
    }
    return dtOptions;
  }
}

const Corrections = ({ crud, contract_id }) => {

  const [loading, setLoading] = useState(false);
  const { messageBox } = useOutletContext();
  const [paramsModalWindow, dispatchModalWindow] = useReducer(reducerModalWindow, { show: false });

  const handleAddRow = () => {
    dispatchModalWindow({ type: 'add' });
  };
  const handleCloseModalWindow = (result) => {
    if (result.modalResult === 'mrOk') dtTable.Api.ajax.reload(null, false);
    dispatchModalWindow({ type: 'destroy' });
  };
  const handleRefreshClick = (event, data) => {
    dtTable.dtParams = { ...data };
    dtTable.Api.ajax.reload();
  }
  const handleAfterDTCreate = ({ DOM, Api }) => {
    dtTable.Api = Api;
    $(DOM).on('click', 'tbody button[name="btnDelete"]', function (e) {
      e.preventDefault();
      let data = dtTable.Api.row($(this).parents('tr')).data();
      let urlDelete = ROUTE_URL + "/" + data.id;
      messageBox({
        show: true, message: `Вы действительно хотите удалить списание на сумму "${data.summa}"?`, mbOk: (e) => {
          e.preventDefault();
          messageBox({ show: false, mbOk: null });
          requestToServer(urlDelete, { method: 'DELETE' }, () => { dtTable.Api.ajax.reload(); }, (error) => { alert(JSON.stringify(error)); }, false);
        }
      });
    });
    $(DOM).on('click', 'tbody a[name="btnShowEdit"]', function (e) {
      e.preventDefault();
      let data = dtTable.Api.row($(this).parents('tr')).data();
      dispatchModalWindow({ type: 'edit', id: data.id });
    });
  }

  return (
    <>
      <div className="container-xxl">
        <SelectPeriod loading={loading} refreshClick={handleRefreshClick} params={dtTable.dtParams}>
          {
            (crud & 8) !== 0 && <div><button type="button" className="btn btn-primary btn-sm btn-our" onClick={handleAddRow}>Добавить</button></div>
          }
        </SelectPeriod>
        <TableDT addFooter getOptions={() => dtTable.getTableOptions(crud, "/contracts/" + contract_id + "/corrections", contract_id, setLoading)} afterCreate={handleAfterDTCreate} />
      </div>
      {paramsModalWindow.show && <Correction params={paramsModalWindow} handleClose={handleCloseModalWindow} contract_id={contract_id} ROUTE_URL={ROUTE_URL} />}
    </>
  );
}

export default Corrections;
