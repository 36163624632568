import { ModalWindow } from "../UI/ModalWindow";
import { Input, Select } from '../UI/FormElements'
import { SelectReactAsync } from "../UI/FormElementsReactSelect";

let initialData = {
  data: { tariff_plan_id: 1, contract_id: null, dat_begin: "", dat_end: "" },
  format: { dat_begin: "date", dat_end: "date" }
};

function ContractTariffPlan({ params, contract_id, ROUTE_URL, handleClose }) {
  let urlSubmit, urlGet;
  if (params.isNew) {
    urlSubmit = ROUTE_URL;
    urlGet = ROUTE_URL + `/create?contract_id=${contract_id}`; //или ROUTE_URL + "/create" если при создании НАДО предварительное обращение к серверу
  }
  else {
    urlGet = ROUTE_URL + "/" + params.id;
    urlSubmit = urlGet;
  }

  initialData.data.contract_id = contract_id;
  return (
    <ModalWindow params={params} handleClose={handleClose} initialData={initialData} urlGet={urlGet} urlSubmit={urlSubmit} >
      <ContractTariffPlanFields />
    </ModalWindow >
  );
}

const ContractTariffPlanFields = ({ dopParams, ...elementProps }) => {

  return (
    <div className="row form-gutter">
      <div className="col-md-12">
        <SelectReactAsync label="Абонент, договор" name="contract_id" isDisabled={initialData.data.contract_id != -1} {...elementProps}
          defaultValue={dopParams.serverData.contracts.filter(item => item.value == elementProps.data.contract_id)}
          defaultOptions={dopParams.serverData.contracts} loadOptions="/find_contracts" />
      </div>
      <div className="col-md-12">
        <Select label="Тарифный план" name="tariff_plan_id" {...elementProps} selectOptions={dopParams.serverData.tariff_plans} />
      </div>
      <div className="col-md-6">
        <Input label="Действует с" type="date" name="dat_begin" {...elementProps} required />
      </div>
      <div className="col-md-6">
        <Input label="Действует по" type="date" name="dat_end" {...elementProps} />
      </div>
    </div>
  )
}

export default ContractTariffPlan;