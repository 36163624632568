import { useReducer } from "react";
import { useOutletContext } from "react-router-dom";
import $ from "jquery";
import DataTable from "datatables.net";
import { getDataTableDom, requestToServer } from '../utils/appUtils'
import { reducerModalWindow } from "../UI/ModalWindow";
import Device from "./Device";
import TableDT from "../UI/TableDT";

const ROUTE_URL = "/devices";

let dtTable = {
  Api: null,
  getTableOptions: (crud, url) => {
    const dtOptions = {
      ordering: true, lengthChange: true, filter: true, paging: true, info: true, processing: true,
      order: [[2, "desc"], [1, "desc"]],
      ajax: { type: "GET", url: url },
      dom: getDataTableDom("lfip", 2, 1),
      columns: [
        { orderable: false, searchable: false, data: null, className: "dt-center td-btn-action", render: DataTable.render.btnDelete(), },
        { data: "id", title: "Код", className: "dt-body-right", },
        { data: "typ_str", title: "Тип", render: DataTable.render.dataToAnchor("btnShowEdit"), },
        { data: 'model', title: "Модель", render: DataTable.render.text(), },
        { data: "state_number", title: "Гос. номер", render: DataTable.render.text(), },
        { data: "loc_number", title: "Гаражный номер", render: DataTable.render.text(), },
        { data: "serial_number", title: "Серийный номер", render: DataTable.render.text(), },
        { data: "inventory_number", title: "Инв. номер", render: DataTable.render.text(), },
        { data: "created_at", title: "Дата ввода", render: DataTable.render.moment("DD.MM.YYYY HH:mm:ss", true), },
        { data: "updated_at", title: "Дата изменения", render: DataTable.render.moment("DD.MM.YYYY HH:mm:ss", true), },
      ],
    };
    if ((crud & 1) === 0) {
      dtOptions.order = [[0, "asc"]];
      dtOptions.columns.splice(0, 1);
      dtOptions.dom = getDataTableDom("lfip", 1, 1);
    }
    return dtOptions;
  }
}

const Devices = ({ crud, contract_id }) => {
  const { messageBox } = useOutletContext();
  const [paramsModalWindow, dispatchModalWindow] = useReducer(reducerModalWindow, { show: false, className: "modal-lg" });

  const handleAddRow = () => {
    dispatchModalWindow({ type: 'add' });
  };
  const handleCloseModalWindow = (result) => {
    if (result.modalResult === 'mrOk') dtTable.Api.ajax.reload(null, false);
    dispatchModalWindow({ type: 'destroy' });
  };
  const handleAfterDTCreate = ({ DOM, Api }) => {
    dtTable.Api = Api;
    $(DOM).on('click', 'tbody button[name="btnDelete"]', function (e) {
      e.preventDefault();
      let data = dtTable.Api.row($(this).parents('tr')).data();
      let urlDelete = ROUTE_URL + "/" + data.id;
      messageBox({
        show: true, message: `Вы действительно хотите удалить "${data.model}"?`, mbOk: (e) => {
          e.preventDefault();
          messageBox({ show: false, mbOk: null });
          requestToServer(urlDelete, { method: 'DELETE' }, () => { dtTable.Api.ajax.reload(); }, (error) => { alert(JSON.stringify(error)); }, false);
        }
      });
    });
    $(DOM).on('click', 'tbody a[name="btnShowEdit"]', function (e) {
      e.preventDefault();
      let data = dtTable.Api.row($(this).parents('tr')).data();
      dispatchModalWindow({ type: 'edit', id: data.id });
    });
  }

  return (
    <>
      <div>
        {
          (crud & 8) != 0 && <div><button type="button" className="btn btn-primary btn-sm btn-our" onClick={handleAddRow}>Добавить</button></div>
        }
        <TableDT getOptions={() => dtTable.getTableOptions(crud, "/contracts/" + contract_id + "/devices")} afterCreate={handleAfterDTCreate} />
      </div>
      {paramsModalWindow.show && <Device params={paramsModalWindow} handleClose={handleCloseModalWindow} contract_id={contract_id} ROUTE_URL={ROUTE_URL} />}
    </>
  );
}

export default Devices;
