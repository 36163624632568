import { NumericFormat } from "react-number-format";

export const InputNumber = (props) => {
  let { label, data, errors, onChange, ...params } = { ...props };
  let error = (typeof errors === "object" && !Array.isArray(errors)) ? errors[params.name] : errors;
  if (!("value" in params) && data)
    params.value = data[params.name];
  params.className = "form-control " + (params.className ? params.className : "") + (error ? " is-invalid" : "");
  if (!params.className.includes("text-"))
    params.className = params.className + " text-end";
  if (!("allowedDecimalSeparators" in params)) params.allowedDecimalSeparators = [','];
  if (!("valueIsNumericString" in params)) params.valueIsNumericString = true;
  if (onChange)
    params.onValueChange = onChange;

  return (
    <>
      {label && <label className="form-label">{label}</label>}
      <NumericFormat {...params} />
      <div className="invalid-feedback">{error}</div>
    </>
  );
}

