import { useState } from "react";
import { Link } from "react-router-dom";
import { enterChangeFocus, requestToServer, formatServerErorrs } from '../utils/appUtils'
import AuthWindow from "./AuthWindow";
import { AuthInput, AuthPanelButton } from "./AuthElements";
import { ButtonSpinner } from "../UI/FormElements";

const ForgotPassword = () => {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [serverData, setServerData] = useState(null);

  const handleSubmitForm = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let formData = new FormData(event.target);
    setLoading(true);
    setErrors({});
    requestToServer("/forgot-password", { method: 'POST', body: formData },
      (response) => { setServerData(response); setLoading(false); },
      (error) => { setLoading(false); setErrors(formatServerErorrs(event.target, error)); }, false);
  }

  if (serverData !== null)
    return (
      <AuthWindow errors={errors}>
        <div className="row">
          <div className="col-12 mb-3 text-center">
            Письмо для восстановления пароля отправлено по адресу {serverData.email}
          </div>
          <div className="col-12 text-center">
            <Link to="/login" className="btn btn-primary btn-our">Закрыть</Link>
          </div>
        </div>
      </AuthWindow>
    );
  else
    return (
      <AuthWindow errors={errors}>
        <form onSubmit={handleSubmitForm}>
          <fieldset disabled={loading}>
            <AuthInput label="Логин" name="login" onKeyDown={enterChangeFocus} errors={errors} required autoFocus />
            <AuthPanelButton>
              <ButtonSpinner loading={loading}>Восстановить пароль</ButtonSpinner>
            </AuthPanelButton>
          </fieldset>
        </form>
      </AuthWindow>
    );
}

export default ForgotPassword;