import { MainArea, MainAreaHeader, MainAreaBody } from "../layout/MainArea"

function Reference() {
  return (
    <>
      <MainArea>
        <MainAreaHeader caption="Справка" />
        <MainAreaBody>
        </MainAreaBody>
      </MainArea>
    </>
  );
}

export default Reference;
