import { ModalWindow } from "../UI/ModalWindow";
import { Input, Select } from '../UI/FormElements'

const ROUTE_URL = "/tariff_services";

let initialData = {
  data: { tariff_plan_id: 0, service_id: 1, unit_id: 1, summa: null },
};

function TariffService({ params, handleClose, tariff_plan_id }) {
  let urlSubmit, urlGet;

  if (params.isNew) {
    urlSubmit = ROUTE_URL;
    urlGet = ROUTE_URL + "/create"; //или ROUTE_URL + "/create" если при создании НАДО предварительное обращение к серверу
  }
  else {
    urlGet = ROUTE_URL + "/" + params.id;
    urlSubmit = urlGet;
  }

  initialData.data.tariff_plan_id = tariff_plan_id;
  return (
    <ModalWindow params={params} handleClose={handleClose} initialData={initialData} urlGet={urlGet} urlSubmit={urlSubmit} >
      <TariffServiceFields />
    </ModalWindow >
  );
}

const TariffServiceFields = ({ dopParams, ...elementProps }) => {
  return (
    <div className="row form-gutter">
      <div className="col-md-12">
        <Select label="Тарифный план" name="tariff_plan_id" {...elementProps}
          selectOptions={dopParams.serverData.tariff_plans.filter(item => item.id == initialData.data.tariff_plan_id)} />
      </div>
      <div className="col-md-12">
        <Select label="Услуга" name="service_id" {...elementProps} selectOptions={dopParams.serverData.services} />
      </div>
      <div className="col-md-6">
        <Select label="Единица учета" name="unit_id" {...elementProps} selectOptions={dopParams.serverData.units} />
      </div>
      <div className="col-md-6">
        <Input label="Тариф" name="summa" {...elementProps} required />
      </div>
    </div>
  )
}

export default TariffService;