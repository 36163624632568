import { ModalWindow } from "../UI/ModalWindow";
import { Input } from '../UI/FormElements'
import { InputNumber } from '../UI/FormElementsMask';
import { SelectReactAsync } from "../UI/FormElementsReactSelect";

let initialData = {
  data: { dat: "", summa: "", comment: "", contract_id: null },
  format: { dat: "date" }
};

function Payment({ params, contract_id, ROUTE_URL, handleClose }) {
  let urlSubmit, urlGet;
  if (params.isNew) {
    urlSubmit = ROUTE_URL;
    urlGet = ROUTE_URL + `/create?contract_id=${contract_id}`; //или ROUTE_URL + "/create" если при создании НАДО предварительное обращение к серверу
  }
  else {
    urlGet = ROUTE_URL + "/" + params.id;
    urlSubmit = urlGet;
  }
  initialData.data.contract_id = contract_id;

  return (
    <ModalWindow params={params} handleClose={handleClose} initialData={initialData} urlGet={urlGet} urlSubmit={urlSubmit} >
      <PaymentFields />
    </ModalWindow >
  );
}

const PaymentFields = ({ dopParams, ...elementProps }) => {

  return (
    <div className="row form-gutter">
      <div className="col-md-6">
        <Input label="Дата платежа" type="date" name="dat" {...elementProps} required autoFocus />
      </div>
      <div className="col-md-6">
        <InputNumber label="Сумма платежа" name="summa" {...elementProps} required thousandSeparator=" " decimalScale={2} />
      </div>
      <div className="col-12">
        <Input label="Описание платежа" name="comment" {...elementProps} required />
      </div>
      <div className="col-12">
        <SelectReactAsync label="Абонент, договор" name="contract_id" isDisabled={initialData.data.contract_id != -1} {...elementProps}
          defaultValue={dopParams.serverData.contracts.filter(item => item.value == elementProps.data.contract_id)}
          defaultOptions={dopParams.serverData.contracts} loadOptions="/find_contracts" />
      </div>
    </div>
  )
}
export default Payment;
