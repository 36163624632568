import { Modal, Button } from 'react-bootstrap';

function MessageBox({ params }) {
  return (
    <>
      <Modal show={params.show} onHide={params.mbClose} centered>
        <Modal.Header className="py-2" closeButton>
          <Modal.Title className="h6">{params.caption}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-nowrap align-items-center">
          <div><i className="bi-question-circle-fill text-primary fs-1 me-3"></i></div>
          <div>{params.message}</div>
        </Modal.Body>
        <Modal.Footer className="pb-2 pt-0 border-0">
          {params.mbOk && <Button className="btn-our" variant="primary" onClick={params.mbOk}>Да</Button>}
          <Button className="btn-our" variant="secondary" onClick={params.mbClose}>Нет</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MessageBox;